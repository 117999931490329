import {css, SerializedStyles} from '@emotion/react'

import scribeIcon from '../images/products/scribe.svg'
import nerdIcon from '../images/products/nerd.svg'
import linkIcon from '../images/products/link.svg'
import extractIcon from '../images/products/extract.svg'
import scribeIconDark from '../images/products/scribe-dark.svg'
import nerdIconDark from '../images/products/nerd-dark.svg'
import linkIconDark from '../images/products/link-dark.svg'
import extractIconDark from '../images/products/extract-dark.svg'
import scribeHeroLarge from '../images/products/ScribeHeroLarge.png'
import scribeHeroMed from '../images/products/ScribeHeroMed.png'
import linkHeroLarge from '../images/products/LinkHeroLarge.png'
import linkHeroMed from '../images/products/LinkHeroMed.png'
import nerdHeroLarge from '../images/products/NerdHeroLarge.png'
import nerdHeroMed from '../images/products/NerdHeroMed.png'
import extractHeroLarge from '../images/products/ExtractHeroLarge.png'
import extractHeroMed from '../images/products/ExtractHeroMed.png'
import getBackgroundCss from '../utils/getBackgroundCss'

const linkCss = css`
  color: #007a99;
  text-decoration: none;
`

export default interface ProductCardInfoProps {
  id: string
  title: string
  icon: string
  iconDark?: string
  productName: string
  summary: string
  details: string
  detailsList: (string | JSX.Element)[]
  trialDetails: string
  productLink: string
  signInLink: string
  contactLink: string
  signupDocumentTitle: string
  signupBackgroundCss: SerializedStyles
  signupDetails?: string
  signupFormSubtitle: string
  learnMoreLink: string
  subtitle: string
}

interface ProductCardInfo {
  [product: string]: ProductCardInfoProps
}

export const productCardInfo: ProductCardInfo = {
  scribe: {
    id: 'scribe',
    title: 'Speech-to-Text Transcription',
    icon: scribeIcon,
    iconDark: scribeIconDark,
    productName: 'Scribe AI',
    summary:
      'Scribe is a transcription solution specifically optimized for tricky, real-world audio.',
    details: 'Scribe transcribes your audio with unparalleled accuracy, speed and security:',
    detailsList: [
      'Provides a 25-point increase in accuracy over well-known transcription services.',
      'Processes 2 minutes of audio in less than a second.',
      <>
        Read about Scribe in our blog post,{' '}
        <a
          href="https://blog.kensho.com/speech-recognition-for-finance-86983b2b97bd"
          css={linkCss}
          target="_blank"
          rel="noopener noreferrer"
        >
          Speech Recognition for Finance
        </a>
        .
      </>,
    ],
    trialDetails: '150 Minutes of Free Transcripts',
    productLink: 'https://scribe.kensho.com/',
    signInLink: `https://scribe.kensho.com/login?${new URLSearchParams({
      returnTo: encodeURIComponent('https://scribe.kensho.com/transcription'),
    })}`,
    contactLink: 'https://kensho.com/contact/solutions?product=scribe',
    signupDocumentTitle: 'Scribe Free Trial | Kensho',
    signupBackgroundCss: getBackgroundCss(scribeHeroMed, scribeHeroLarge),
    signupDetails: 'Transcribe complex business audio into text, quickly and accurately',
    signupFormSubtitle: 'Unlock 150 minutes of free transcripts today with Kensho Scribe!',
    learnMoreLink: 'https://kensho.com/scribe',
    subtitle: 'Transcribe complex business audio into text, quickly and accurately',
  },
  nerd: {
    id: 'nerd',
    title: 'Identify Entities in Text',
    icon: nerdIcon,
    iconDark: nerdIconDark,
    productName: 'NERD',
    summary:
      'Unlock new connections and deeper insights by systematically uncovering the companies, people, places, events, and more in your text.',
    details:
      'Identify the who, what and where in text and connect them to Capital IQ or Wikimedia (nearly 100M entities are identifiable!) ',
    detailsList: [
      'Streamline manual workflows by annotating thousands of words in seconds.',
      'Reveal insights by analyzing NERD output directly or in conjunction with Capital IQ or Wiki data.',
      'Supercharge research workflows with interconnected content.',
      'Improve search results, content recommendations, and document routing.',
    ],
    trialDetails: '500 Pages of Free Entity Annotations',
    productLink: 'https://nerd.kensho.com/',
    signInLink: `https://nerd.kensho.com/login?${new URLSearchParams({
      returnTo: encodeURIComponent('https://nerd.kensho.com/projects'),
    })}`,
    contactLink: 'https://kensho.com/contact/solutions?product=nerd',
    signupDocumentTitle: 'Nerd Free Trial | Kensho',
    signupBackgroundCss: getBackgroundCss(nerdHeroMed, nerdHeroLarge),
    signupDetails: 'Identify the entities in text and enrich them with external data',
    signupFormSubtitle: 'Unlock 500 pages of free entity annotations today with Kensho NERD!',
    learnMoreLink: 'https://kensho.com/nerd',
    subtitle:
      'Identify companies, organizations, people, places and events in text and connect them to rich knowledge bases.',
  },
  link: {
    id: 'link',
    title: 'Map Companies to External Databases',
    icon: linkIcon,
    iconDark: linkIconDark,
    productName: 'Link',
    summary:
      "Kensho Link connects your messy company data with likely matches in S&P Global's Company IDs, allowing you to benefit from the unparalleled quality and depth of S&P Global's company data.",
    details: 'No UID? No problem! No need to spend time training an ML model.',
    detailsList: [
      'Input the data you have for a given entity and receive back probable links.',
      "Quickly spot the most relevant matches for each entity using Link's match scores.",
      'Get matches with as little as a name, but improve the accuracy by providing more fields.',
      "Gain access to all of S&P's extensive data on your matched entity with S&P Global's Company IDs.",
    ],
    trialDetails: '500 Rows of Free Database Links',
    productLink: 'https://link.kensho.com/',
    signInLink: `https://link.kensho.com/login?${new URLSearchParams({
      returnTo: encodeURIComponent('https://link.kensho.com/projects'),
    })}`,
    contactLink: 'https://kensho.com/contact/solutions?product=link',
    signupDocumentTitle: 'Link Free Trial | Kensho',
    signupBackgroundCss: getBackgroundCss(linkHeroMed, linkHeroLarge),
    signupDetails: 'Connect your company data to unique S&P Global IDs',
    signupFormSubtitle: 'Unlock 500 rows of free database links today with Kensho Link!',
    learnMoreLink: 'https://kensho.com/link',
    subtitle: 'Connect messy company data to unique S&P Global IDs.',
  },
  extract: {
    id: 'extract',
    title: 'Extract Data from PDF Documents',
    icon: extractIcon,
    iconDark: extractIconDark,
    productName: 'Extract',
    summary:
      'Kensho Extract enables you to automate extraction of critical insights from tables and text in complex and inconsistent PDF documents.',
    details:
      'Extract information exactly as represented in the original document or standardize table contents such as financial metrics, periods, currencies and other information.',
    detailsList: [
      'Save manual processing time.',
      'Structure data for downstream analytics',
      'Flatten and standardize information in tables.',
    ],
    trialDetails: '500 Pages of Free Extractions',
    productLink: 'https://extract.kensho.com/',
    signInLink: `https://extract.kensho.com/login?${new URLSearchParams({
      returnTo: encodeURIComponent('https://extract.kensho.com/app'),
    })}`,
    contactLink: 'https://kensho.com/contact/solutions?product=extract',
    signupDocumentTitle: 'Extract Free Trial | Kensho',
    signupBackgroundCss: getBackgroundCss(extractHeroMed, extractHeroLarge),
    signupDetails: 'Make PDF documents easier to work with, search through, and structure',
    signupFormSubtitle: 'Unlock 500 pages of free extractions today with Kensho Extract!',
    learnMoreLink: 'https://kensho.com/extract',
    subtitle: 'Make PDF documents easier to work with, search through, and structure.',
  },
}

export const launchProductCardList = [
  productCardInfo.scribe,
  productCardInfo.nerd,
  productCardInfo.extract,
  productCardInfo.link,
]

export const productCardList = [
  productCardInfo.scribe,
  productCardInfo.nerd,
  productCardInfo.link,
  productCardInfo.extract,
]
